/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "what-is-swimmers-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-swimmers-ear",
    "aria-label": "what is swimmers ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is Swimmer’s Ear?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Swimmer’s ear — or otitis externa — is an infection of the outer ear canal, the part of the canal that runs from the eardrum to the outside of the head. Though it’s more common in people who swim, anyone can get it. ", React.createElement("a", {
    href: "https://www.cdc.gov/healthywater/swimming/swimmers/rwi/ear-infections.html",
    target: "_blank"
  }, "According to the CDC"), ", Swimmer’s ear is most often seen in children."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-causes-swimmers-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-causes-swimmers-ear",
    "aria-label": "what causes swimmers ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What causes Swimmer’s ear?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The primary cause of Swimmer’s ear is water entering and remaining in the ear canal. This can happen after swimming, bathing, or even spending time in warm, humid places."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Ears have natural defenses to protect against injury and infection. First, they secrete cerumen, commonly known as earwax. Cerumen forms a water-repellent barrier inside the ear and is slightly acidic (pH 5), which ", React.createElement("a", {
    href: "https://www.sciencedirect.com/science/article/pii/S1726490109701741",
    target: "_blank"
  }, "inhibits bacterial and fungal growth"), ". Second, ears have a piece of cartilage called the tragus that partially covers the canal opening and helps prevent foreign bodies from entering. In Swimmer’s ear, these defenses get overwhelmed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A common contributing factor is putting objects in the ear. Cotton swabs, fingers, pens, keys, bobby pins and earbuds can all injure the skin inside the ear canal and lead to infection. Other risk factors include swimming in unclean or polluted water, having a dry ear canal, extra ear wax, heavy perspiration, having an unusually narrow or hairy ear canal, or having eczema, psoriasis, or other skin conditions."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Rather than fungus or viruses, Swimmer’s ear is typically a bacterial infection. The most common bacteria in Swimmer’s ear infections is ", React.createElement("a", {
    href: "https://www.cdc.gov/hai/organisms/pseudomonas.html",
    target: "_blank"
  }, "pseudomonas aeruginosa"), "."), "\n", React.createElement(LandingPageCta, {
    copy: "Learn more from hear.com",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-the-symptoms-of-swimmers-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-symptoms-of-swimmers-ear",
    "aria-label": "what are the symptoms of swimmers ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are the symptoms of Swimmer’s ear?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The primary symptoms of mild to moderate Swimmer’s ear include redness of the outer ear, itching inside the ear, ear pain that gets worse when pulling on the earlobe or pushing the cartilage in front of the ear canal, and pus or drainage from the ear. These may also be accompanied by a full or plugged-up feeling in the ear or pain when chewing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If left untreated, Advanced Swimmer’s ear can occur. Symptoms of an advanced infection include severe, radiating pain of the face, neck, or head, complete blockage of the ear canal, swelling and redness of the outer ear, swelling of the lymph nodes in the neck, fever, and yellowish-brown, bad-smelling pus."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "can-swimmers-ear-damage-your-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#can-swimmers-ear-damage-your-hearing",
    "aria-label": "can swimmers ear damage your hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Can Swimmer’s ear damage your hearing?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Because of the ear canal’s swelling and potential total blockage, Swimmer’s ear can cause muffled hearing or even hearing loss. These symptoms typically get better after the infection clears."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-the-complications-of-swimmers-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-complications-of-swimmers-ear",
    "aria-label": "what are the complications of swimmers ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are the complications of Swimmer’s ear?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If left untreated, Swimmer’s ear can progress and cause serious complications. Complications include hearing loss (generally temporary) and recurrent ear infections. Chronic otitis externa is a long-term Swimmer’s ear infection lasting more than three months. It can occur if another condition makes treatment more difficult, such as a rare bacterial strain, a combination bacterial/fungal infection, an allergic reaction to antibiotic ear drops, or psoriasis."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In rare cases, Swimmer’s ear infections can spread to surrounding tissue and become a condition known as malignant otitis externa. In these cases, an infection can spread to the cartilage, connective tissue, and bone around the ear, causing permanent damage. Infection can even spread through the skull to the brain and cranial nerves and can be life-threatening. Advanced cases may also cause paralysis of facial nerves."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-is-swimmers-ear-diagnosed",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-is-swimmers-ear-diagnosed",
    "aria-label": "how is swimmers ear diagnosed permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How is Swimmer’s ear diagnosed?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your healthcare provider suspects Swimmer’s ear, they will look in both ears using an otoscope (a lighted tool to check for middle ear infections). The provider will look for shedding or scaliness of the skin in the ear canal. If there is pus or drainage, the provider may take a sample and run tests to find the exact strain of the infection."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-is-swimmers-ear-treated",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-is-swimmers-ear-treated",
    "aria-label": "how is swimmers ear treated permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How is Swimmer’s ear treated?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The standard treatment for Swimmer’s ear is a course of prescription antibiotic ear drops. Oral antibiotics are rarely used to treat Swimmer’s ear. If there is significant swelling, the provider may also prescribe corticosteroid ear drops. Use all ear drops as directed."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In more severe cases, ear drops alone may not be effective because of the physical blockage of the canal by debris and swelling. If the blockage is caused by material inside the canal, the doctor will clear it using irrigation or gentle suction. This process will expose the infected area to apply medication. If the blockage is due to advanced swelling, the doctor may insert a wick into the ear to help the ear drops reach the infection."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Continuing treatments at home include OTC pain medication, keeping the ear dry by taking baths instead of showers, and using a heating pad on the lowest setting to ease pain. If using a heating pad, there may be some drainage as ear wax melts. Never use a heating pad in bed or on a child."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With proper treatment, Swimmer’s ear usually clears in 7-10 days."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-prevent-swimmers-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-prevent-swimmers-ear",
    "aria-label": "how to prevent swimmers ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to prevent Swimmer’s ear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Swimmer’s ear prevention involves keeping water out of the ears or removing water from the ears promptly. To prevent water from entering the ear, wear earplugs when swimming or showering, use a bathing cap and/or custom-fit swim molds, and place cotton balls in the ears while applying hair sprays or dyes."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If water has entered the ear, tilt the head to each side to drain the water out. While tilting, pull the earlobe in different directions to help the water escape. A hairdryer on the lowest setting can be used to dry the ears. Hold the hairdryer at least one foot away from the ear and keep the hair dryer moving."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Drying ear drops can be made at home using 1 part white vinegar and 1 part rubbing alcohol. The drops can be used before and after swimming. Do not use these drops if you have ear tubes, a punctured eardrum, ear drainage, or an active case of Swimmer’s ear. Remember: always consult your healthcare provider before using any ear drops."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "whats-the-difference-between-swimmers-ear-and-a-middle-ear-infection",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#whats-the-difference-between-swimmers-ear-and-a-middle-ear-infection",
    "aria-label": "whats the difference between swimmers ear and a middle ear infection permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What’s the difference between Swimmer’s ear and a middle ear infection?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Swimmer’s ear is different from a middle ear infection. While Swimmer’s ear occurs in the outer ear, middle ear infections occur in the middle ear, the area behind the eardrum. With a middle ear infection, tugging at the ear usually does not cause discomfort as it does in Swimmer’s ear. Finally, middle ear infections may cause other symptoms, including fever, dizziness, nausea, or vertigo."), "\n", React.createElement(LandingPageCta, {
    copy: "Learn more from hear.com",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
